<template>
  <div class="tui tuim ui card">
    <div class="tui tuim ui header">
      <h2>Dados de cobrança</h2>
    </div>
    <form class="tui tuim ui form card-form">

      <div class="content spacer">
        <div class="fields">
          <div class="sixteen wide field">
            <div class="field">
              <label class="label">Forma de pagamento</label>
              <select
                v-model="donation.paymentMethod"
                disabled
                class="ui fluid dropdown disabled">
                <option
                  v-for="(item, key) in paymentMethodOptions" :key="key"
                  :value="item.value"
                  v-text="item.label"
                />
              </select>
            </div>
            <div>
              <t-form-credit-card v-if="donation.paymentMethod === 'credit_card'" />
              <t-form-bank-slip v-if="donation.paymentMethod === 'bank_slip'"/>
              <t-form-debit-account v-if="donation.paymentMethod === 'debit_account'" />
              <t-form-pix v-if="donation.paymentMethod === 'pix'"/>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'TFormBillingDonation',

  components: {
    TFormBankSlip: () => import('./form-bank-slip.vue'),
    TFormCreditCard: () => import('./form-credit-card.vue'),
    TFormDebitAccount: () => import('./form-debit-account.vue'),
    TFormPix: () => import('./form-pix.vue')
  },

  data() {
    return {
      paymentMethodOptions: [
        { value: 'credit_card', label: 'Cartão de Crédito' },
        { value: 'bank_slip', label: 'Boleto' },
        { value: 'debit_account', label: 'Débito' },
        { value: 'pix', label: 'Pix' }
      ],
    }
  },

  computed: {
    ...mapState({
      donation: ({ donations }) => donations.donationDetail
    })
  },

  methods: {
     bindPluginsFather() {
      $('.ui.fluid.dropdown').dropdown()
    }
  },

  mounted() {
    this.bindPluginsFather()
  }
}
</script>

<style lang="scss">
@import '@/assets/sass/colors';
@import '@/assets/sass/card-form';
</style>
